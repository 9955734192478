.gallery-container{
   margin-top: 64px;
   margin-bottom: 64px;
   min-height: 60vh;

}


.image-grid-gallery {
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
   gap: 8px;
   /* padding: 64px 0px 64px 0px; */
   /* background-color: #CCB98B; */
   /* margin: 64px 0px 64px 0px; */


 
 }

.angry-grid {
    display: grid; 
    grid-template-rows: 240px 240px 240px ;
    grid-template-columns:  420px 420px 420px;
    gap: 8px;
    /* background-color: #CCB98B; */


 }
   
 #item-0 {
 
    background-color: #000000; 
    grid-row-start: 1;
    grid-column-start: 1;
 
    grid-row-end: 3;
    grid-column-end: 2;
    position: relative;

    
 }
 #item-1 {
 
    background-color: #000000; 
    grid-row-start: 3;
    grid-column-start: 1;
 
    grid-row-end: 4;
    grid-column-end: 2;
    
 }
 #item-2 {
 
    background-color: #000000; 
    grid-row-start: 1;
    grid-column-start: 2;
 
    grid-row-end: 2;
    grid-column-end: 4;
    
 }
 #item-3 {
 
    background-color: #000000; 
    grid-row-start: 2;
    grid-column-start: 2;
 
    grid-row-end: 4;
    grid-column-end: 3;
    
 }
 #item-4 {
 
    background-color: #000000; 
    grid-row-start: 2;
    grid-column-start: 3;
 
    grid-row-end: 3;
    grid-column-end: 4;
    
 }
 #item-5 {
 
    background-color: #000000; 
    grid-row-start: 3;
    grid-column-start: 3;
 
    grid-row-end: 4;
    grid-column-end: 4;
    
 }
 .angry-grid img {
    width:100%;
    height:100%;
    object-fit:cover;
    transition:1s;
    transition-delay:.2s;
    opacity:0.7;
    /* background-color: #000000; */
    /* filter: brightness(50%); */


  }

  /* .mar{
    margin-top: 60px;
    background-color: #56E899;
    margin-bottom: 60px;

  } */





  @media (max-width: 1292px) {
    .angry-grid {
        display: grid; 
        grid-template-rows: 168px 168px 168px ;
        grid-template-columns:  294px 294px 294px;
        gap: 8px;
        /* background-color: #56E899; */
        /* margin-bottom: 8px; */
        /* position: absolute; */
    
     }
    
    }

    @media (max-width: 972px) {
        .angry-grid {
            display: grid; 
            grid-template-rows: 120px 120px 120px ;
            grid-template-columns:  210px 210px 210px;
            gap: 4px;
            /* background-color: #56E899; */
            /* margin-bottom: 8px; */
            /* position: absolute; */
        
         }
        
        }

        @media (max-width: 698px) {
            .angry-grid {
                display: grid; 
             
                grid-template-rows: 240px 240px 240px 240px 240px 240px;
                grid-template-columns: 158px 158px 158px;
                
                gap: 4px;                
             }
               
             #item-0 {
             
                background-color: #000000; 
                grid-row-start: 1;
                grid-column-start: 1;
             
                grid-row-end: 2;
                grid-column-end: 4;
                
             }
             #item-1 {
             
                background-color: #000000; 
                grid-row-start: 2;
                grid-column-start: 1;
             
                grid-row-end: 3;
                grid-column-end: 4;
                
             }
             #item-2 {
             
                background-color: #000000; 
                grid-row-start: 3;
                grid-column-start: 1;
             
                grid-row-end: 4;
                grid-column-end: 4;
                
             }
             #item-3 {
             
                background-color: #000000; 
                grid-row-start: 4;
                grid-column-start: 1;
             
                grid-row-end: 5;
                grid-column-end: 4;
                
             }
             #item-4 {
             
                background-color: #000000; 
                grid-row-start: 5;
                grid-column-start: 1;
             
                grid-row-end: 6;
                grid-column-end: 4;
                
             }
             #item-5 {
             
                background-color: #000000; 
                grid-row-start: 6;
                grid-column-start: 1;
             
                grid-row-end: 7;
                grid-column-end: 4;
                
             }
            
            }

            @media (max-width: 640px) {
                .angry-grid {
                    display: grid; 
                 
                    grid-template-rows: 220px 220px 220px 220px 220px 220px;
                    grid-template-columns: 134px 134px 134px;
                    
                    gap: 4px;                
                 }
            }