:root{

  --contact-bg-color: #F5F1E8;
  --contact-info-bg-color: #CCB98B;
  --contact-info-font-color: #000000;
  --contact-form-bg-color: #ffffff;
  --contact-form-font-color: #000000;
  --contact-form-placeholder-color: #31302e;


  --contact-btn-bg-color: #ad8e44;
  --contact-btn-hover-bg-color: #ad8e44;
  --contact-btn-font-color: #ffffff;
  --contact-btn-hover-font-color: #ffffff;


}


.contact{
    background-color: var(--contact-bg-color);
}
  .form-control {
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: var(--contact-form-bg-color);
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
    
    @media screen and (prefers-reduced-motion: reduce) {
      .form-control {
        -webkit-transition: none;
        -o-transition: none;
        transition: none; } }
    .form-control::-ms-expand {
      background-color: transparent;
      border: 0; }
    .form-control:focus {
      color: #495057;
      background-color: var(--contact-form-bg-color);
      border-color: #BAE3C8;
      outline: 0;
      -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .form-control::-webkit-input-placeholder {
      color: var(--contact-form-placeholder-color);
      opacity: 1; }
    .form-control:-ms-input-placeholder {
      color: var(--contact-form-placeholder-color);
      opacity: 1; }
    .form-control::-ms-input-placeholder {
      color: var(--contact-form-placeholder-color);
      opacity: 1; }
    .form-control::placeholder {
      color: var(--contact-form-placeholder-color);
      opacity: 1; }
    .form-control:disabled, .form-control[readonly] {
      background-color: var(--contact-form-bg-color);
      opacity: 1; }
  
  select.form-control:focus::-ms-value {
    /* color: #327dc7; */
    background-color: #fff; 
  }
  
  select.form-control[size], select.form-control[multiple] {
    height: auto; }
  
  textarea.form-control {
    height: auto; }
  
  .form-group {
    margin-bottom: 1rem; }


  
  .btn-primary {
    display: inline-block;
    padding: 13px 20px;
    color: var(--contact-btn-font-color);
    text-decoration: none;
    position: relative;
    background-color: var(--contact-btn-bg-color);
    border:1px solid var(--contact-btn-bg-color); 
    border-radius: 4px;
    font-size:10px;
    letter-spacing: .4em;
    text-align: center;
    text-indent: 2px;
    text-transform: uppercase;
    transition: color .1s linear 0.05s;

    }
    .btn-primary:hover {
      color: var(--contact-btn-hover-font-color);
      background-color: var(--contact-btn-hover-bg-color);
      border-color: var(--contact-btn-hover-bg-color); 
    }


  

  
  
  .form-control {
    height: 52px !important;
    background: var(--contact-form-bg-color) !important;
    color: #000000 !important;
    font-size: 14px;
    border-radius: 5px;
    -webkit-box-shadow: none !important;
    box-shadow: none !important; }
    .form-control:focus, .form-control:active {
      border-color: #000000; }
  
  textarea.form-control {
    height: inherit !important; }
  
  


  
  
  .wrapper {
    width: 100%; 
    padding: 6rem 0rem;

}
  
  .contact-wrap {
    background: var(--contact-form-bg-color); 
    padding: 6rem 0rem;
}
  
  .info-wrap {
    color: var(--contact-info-font-color); 
    background-color: var(--contact-info-bg-color);
  }
    .info-wrap h3 {
      color: var(--contact-info-font-color); }
    .info-wrap .dbox {
      width: 100%;
      color: var(--contact-info-font-color);
      margin-bottom: 25px; }
      .info-wrap .dbox:last-child {
        margin-bottom: 0; }
      .info-wrap .dbox p {
        margin-bottom: 0; }
        .info-wrap .dbox p span {
          font-weight: 500;
          color: var(--contact-info-font-color); display: block;}
        .info-wrap .dbox p a {
          color: var(--contact-info-font-color); }
      .info-wrap .dbox .icon {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 1px solid rgba(255, 255, 255, 1); 
        margin-right: 12px;
    }
        .info-wrap .dbox .icon .icon-img {
          font-size: 22px;
          color: var(--contact-info-font-color); }
      .info-wrap .dbox .text {
        width: calc(100% - 50px); }
  
  .contactForm .label {
    color: #000000;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600; }
  
  .contactForm .form-control {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0; }
  
 
    @media (max-width: 992px) {

      .wrapper {
        width: 100%; 
        padding: 1rem 0rem;
    
    }
      
      }




























/*   
  .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto; }
    @media (min-width: 576px) {
      .container {
        max-width: 540px; } }
    @media (min-width: 768px) {
      .container {
        max-width: 720px; } }
    @media (min-width: 992px) {
      .container {
        max-width: 960px; } }
    @media (min-width: 1200px) {
      .container {
        max-width: 1140px; } }
  
  .container-fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto; }
  
  .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px; }
  
  .no-gutters {
    margin-right: 0;
    margin-left: 0; }
    .no-gutters > .col,
    .no-gutters > [class*="col-"] {
      padding-right: 0;
      padding-left: 0; }
  

   */
