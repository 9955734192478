.collaborators {
    justify-content: center;
    margin-top: 28px;
    margin-bottom: 28px;
    min-height: 60vh;

}

.main-heading {
    text-align: center;
    margin: 30px;
    font-size: 36px;
    font-weight: 600;
}

.collaborators .card-collaborator {
    /* box-shadow: 0 6px 6px rgba(0, 0, 0, 0.22), 0 6px 6px rgba(0, 0, 0, 0.22); */
    font-family: 'Trebuchet MS', sans-serif;
    margin-top: 24px;
    /* width: 90%; */
    padding: 18px;
    background-color: rgb(255, 255, 255);
    border-bottom: 1px solid #AD8E44;
}








.collaborators .personal-info .heading {
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.collaborators .personal-info .sub-heading {
    font-size: 20px;
    margin: 0%;
    line-height: 32px;
    font-weight: 600;
}

.collaborators .personal-info .about-context {
    font-size: 18px;
    margin: 8px 0px;
    line-height: 24px;
    text-align: justify;
}




























.collaborators .one {
    padding: 32px 0px 24px 0px;
}

.collaborators .one h1 {

    position: relative;
    padding: 0;
    margin: 0;
    /* font-family: "Raleway", sans-serif; */
    font-weight: 600;
    font-size: 36px;
    color: #080808;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;

    text-align: center;
    /* text-transform: uppercase; */
    padding-bottom: 5px;
}

.collaborators .one h1:before {
    width: 30px;
    height: 6px;
    display: block;
    content: "";
    position: absolute;
    bottom: 3px;
    left: 50%;
    margin-left: -14px;
    background-color: var(--nav-bg-color);
}

.collaborators .one h1:after {
    width: 320px;
    height: 1px;
    display: block;
    content: "";
    position: relative;
    margin-top: 18px;
    left: 50%;
    margin-left: -160px;
    background-color: var(--nav-bg-color);
}