/* KEYFRAMES */

@keyframes spin {
    from {
      transform: rotate(0);
    }
    to{
      transform: rotate(359deg);
    }
  }
  
  @keyframes spin3D {
    from {
      transform: rotate3d(.5,.5,.5, 360deg);
    }
    to{
      transform: rotate3d(0deg);
    }
  }
  
  @keyframes configure-clockwise {
    0% {
      transform: rotate(0);
    }
    25% {
      transform: rotate(90deg);
    }
    50% {
      transform: rotate(180deg);
    }
    75% {
      transform: rotate(270deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes configure-xclockwise {
    0% {
      transform: rotate(45deg);
    }
    25% {
      transform: rotate(-45deg);
    }
    50% {
      transform: rotate(-135deg);
    }
    75% {
      transform: rotate(-225deg);
    }
    100% {
      transform: rotate(-315deg);
    }
  }
  
  @keyframes pulse {
    from {
      opacity: 1;
      transform: scale(1);
    }
    to {
      opacity: .25;
      transform: scale(.75);
    }
  }
  
  /* GRID STYLING */
  
  * {
    box-sizing: border-box;
  }
  

  
  .spinner-box {

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    position: absolute;
    z-index: 1000;
  }
  

  
  .three-quarter-spinner {
    width: 50px;
    height: 50px;
    border: 3px solid #ad8e44;
    border-top: 3px solid transparent;
    border-radius: 50%;
    animation: spin .5s linear 0s infinite;
  }