:root {
  --primary-font: 'Marcellus', serif !important;
  --secondary-font: 'Jost', serif !important;


  --nav-bg-color: #ad8e44;
  --nav-active-bg-color: #ad8e44;
  --nav-font-color: #000000;
  --nav-active-font-color: #000000;
  --nav-hover-font-color: #ffffff;


  --slider-bg-color: #000000;
  --slider-font-color: #ffffff;
  --slider-arrow-color: #ffffff;
  --slider-ind-color: #ffffff;
  --slider-ind-active-color: #ad8e44;

  --slider-btn-color: #e1e1e1;
  --slider-btn-font-color: #ffffff;
  --slider-btn-hover-color: #373737;


  --about-main-font-color: #000000;
  --about-sub-font-color: #000000;
  --about-bg-color: #CCB98B;


  --footer-bg-color: #000000;
  --footer-font-color: #ffffff;

  --title-card-bg-color: #000000;
  --title-card-main-font-color: #ffffff;
  --title-card-sub-font-color: #ffffff;

  --research-bg-color: #ffffff;
  --research-main-font-color: #000000;
  --research-sub-font-color: #000000;



  --gallery-bg-color: #000000;
  --gallery-font-color: #ffffff;


  --team-top-cont-bg-color: #e9dcbd;
  --team-below-cont-bg-color: #ffffff;
  --team-border-color: #f0e6d0;
  --team-main-font-color: #000000;
  --team-sub-font-color: #636B6F;
  --team-title-font-color: #000000;


  --publication-cal-color: #ad8e44;
  --publication-pdf-color: #FF1300;
  --publication-site-color: #ad8e44;

  --publication-main-font-color: #000000;
  --publication-sub-font-color: #636B6F;


}


/*COMMON*/
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--secondary-font) !important;
}

p {
  font-family: var(--primary-font) !important;
}

/*COMMON*/
/* HERO  */
.carousel {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90vh;
}

.slide {
  border-radius: 0.5rem;
  /* box-shadow: 0px 0px 7px #666; */
  width: 100%;
  height: 100%;

}

.slide-hidden {
  display: none;
}

.image-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  image-rendering: optimizeQuality;
}

.image-container::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
}

.slide-image {
  width: 100%;
  height: 100%;
  object-fit: cover;


}

.slide-content {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  color: var(--slider-font-color);

}

.slide-content .slide-text {
  margin: 0 auto 15px;
  max-width: 80%;
  /* letter-spacing: 0.35em; */
  /* text-transform: uppercase; */
  /* font-size: 48px; */
  font-size: 62px;
  font-weight: 500;
  line-height: 78px;
  text-align: center;
}

.btn {
  margin-top: 18px;
  display: inline-block;
  padding: 13px 20px;
  color: var(--slider-btn-font-color);
  text-decoration: none;
  position: relative;
  background: transparent;
  border: 1px solid var(--slider-btn-color);
  font: 10px "Oswald", sans-serif;
  letter-spacing: 0.4em;
  text-align: center;
  text-indent: 2px;
  text-transform: uppercase;
  transition: color 0.1s linear 0.05s;
}

.btn::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px;
  background: var(--slider-btn-color);
  z-index: 1;
  opacity: 0;
  transition: height 0.2s ease, top 0.2s ease, opacity 0s linear 0.2s;
}

.btn::after {
  transition: border 0.1s linear 0.05s;
}

.btn .btn-inner {
  position: relative;
  z-index: 2;
}

.btn:hover {
  color: var(--slider-btn-hover-color);
  transition: color 0.1s linear 0s;
}

.btn:hover::before {
  top: 0;
  height: 100%;
  opacity: 1;
  transition: height 0.2s ease, top 0.2s ease, opacity 0s linear 0s;
  border-radius: var(--bs-btn-border-radius);
}

.btn:hover::after {
  border-color: var(--slider-btn-hover-color);
  transition: border 0.1s linear 0s;
}

.arrow {
  position: absolute;
  filter: drop-shadow(0px 0px 5px #555);
  width: 3rem;
  height: 3rem;
  color: var(--slider-arrow-color);
  z-index: 1;
}

.arrow:hover {
  cursor: pointer;
  transform: scale(0.8);
  transition: all .2s ease-in-out 0s;
  -webkit-transform: all .2s ease-in-out 0s;
}

.arrow-left {
  left: 2rem;

}

.arrow-right {
  right: 2rem;

}

.indicators {
  display: flex;
  position: absolute;
  bottom: 1rem;
}

.indicator {
  background-color: var(--slider-ind-active-color);
  height: 0.15rem;
  width: 4rem;
  border: none;
  outline: none;
  box-shadow: 0px 0px 5px #555;
  margin: 0 0.2rem;
  cursor: pointer;
}

.indicator-inactive {
  background-color: var(--slider-ind-color);
}

@media (max-width: 1200px) {
  .slide-content .slide-text {

    font-size: 58px;
    font-weight: 500;
    line-height: 68px;
  }
}

@media (max-width: 992px) {
  .slide-content .slide-text {
    font-size: 54px;
    font-weight: 500;
    line-height: 66px;
  }

  .arrow {

    width: 2.5rem;
    height: 2.5rem;

  }
}

@media (max-width: 768px) {
  .slide-content .slide-text {
    /* font-size: 42px; */
    font-size: 52px;
    font-weight: 500;
    line-height: 54px;
  }

  .arrow-left {
    left: 1.5rem;

  }

  .arrow-right {
    right: 1.5rem;

  }
}

@media (max-width: 680px) {
  .slide-content .slide-text {
    /* font-size: 26px; */
    font-size: 34px;
    font-weight: 500;
    line-height: 36px;
  }

  .card-detail .card-details h2 {
    font-size: 28px;
  }

  .arrow-left {
    left: 0.5rem;

  }

  .arrow-right {
    right: 0.5rem;
  }

  .indicator {
    height: 0.15rem;
    width: 3rem;
    margin: 0 0.5rem;

  }
}


/* ABOUT */
.about {
  background-color: var(--about-bg-color);

}

.container {
  position: relative;
  word-wrap: break-word;

}

.content-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6rem 0rem;
}


.card-box h4 {
  font-size: 48px;
  font-weight: bold;
  color: var(--about-main-font-color);
  width: 100%;

}

.card-box p {
  margin-top: 20px;
  font-size: 20px;
  color: var(--about-sub-font-color);
}

.col-img .image-wrapper {
  text-align: right;
  max-height: 450px;
}

.col-img .image-wrapper img {
  width: 450px;
  height: auto;
  /* border-radius: 50%; */
}

@media (max-width: 992px) {
  .content-container {
    flex-direction: column;
    padding: 3%;
  }

  .col-text {
    width: 100%;
  }

  .card-box h4 {
    font-size: 32px;
    font-weight: bold;
    color: #000000;
    width: 100%;
    text-align: center;

  }

  .card-box p {
    margin-top: 20px;
    font-size: 18px;
    text-align: justify;
  }

  .col-img .image-wrapper {
    text-align: center;
  }
}

.col-img .image-wrapper img {
  max-width: 400px;
  width: 100%;
  height: auto;
  /* border-radius: 50%; */
}

/*======================================
	01. Start NavBar CSS
========================================*/
.navarea {
  max-width: 100vw;
}

.navbar {
  height: 100px;
  max-height: 100%;
  width: 100%;
  /* color: var(--nav-font-color); */
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 0 2rem; */
  /* position: fixed; */
  z-index: 1000;
  margin: 0%;
  background-color: var(--nav-bg-color);
  padding: 0 20px;
}

.navbar-active {
  height: 100px;
  max-height: 100%;
  width: 100%;
  /* color: var(--nav-active-font-color); */
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 1000;
  margin: 0%;
  background-color: var(--nav-active-bg-color);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .2);
  animation: goDown 0.5s ease-in-out forwards;
  padding: 0 20px;
}

.nav-logo {
  margin-left: 2rem;
  text-decoration: none;
}

.nav-logo {
  margin-left: 2rem;
}

.nav-logo img {
  width: 126px;
}

.nav-logo .logo-text-name {
  text-transform: uppercase;
  font-family: 'League Spartan', sans-serif;
  margin: 0%;
  color: #000000;
  font-size: 38px;
  font-weight: 400;

}

.nav-links {
  display: grid;
  grid-template-columns: repeat(9, auto);
  grid-gap: 40px;
  list-style: none;
  margin: 0%;
  /* margin-left: 30%; */
}
.nav-link-tab-active {
  text-decoration: none;
  transition: 0.3s all;
  font-family: 'League Spartan', sans-serif;
  font-size: 24px;
  font-weight: 400;
  color: rgb(255, 255, 255);
}
.nav-link {
  text-decoration: none;
  transition: 0.3s all;
  font-family: 'League Spartan', sans-serif;
  font-size: 24px;
  font-weight: 400;
  color: rgb(0, 0, 0);
}



.nav-link:hover {
  color: var(--nav-hover-font-color);
  /* text-decoration: underline; */
}


.nav-icon {
  display: none;
  font-size: 36px;
  cursor: pointer;
}

@media only screen and (max-width: 1240px) {
  .navbar {
    /* position: fixed; */
    height: 10vh;
    justify-content: space-between;
  }

  .navbar-active {
    justify-content: space-between;
  }

  .nav-logo .logo-text-name {

    font-size: 30px;
    font-weight: 400;

  }

  .nav-links {
    display: flex;
    flex-direction: column;
    position: absolute;
    text-align: center;
    width: 100%;
    top: 100px;
    left: -100%;
    transition: 0.5s all;
    z-index: 1000;
  }

  .nav-links.active {
    background: rgb(255, 255, 255);
    left: 0;
    padding: 0%;
    margin: 0%;

  }
  .nav-link:hover {
    color: var(--nav-bg-color);
    /* text-decoration: underline; */
  }
  
  .nav-item {
    padding: 10px 0;
  }

  .nav-icon {
    display: flex;
    margin-right: 2rem;
  }

  .hide {
    display: none;
  }
  .nav-link-tab-active {

    color: var(--nav-bg-color);
  }

}




/*======================================
	01. Start TitleCard CSS
========================================*/
.title-card {
  /* margin-bottom: 4%; */
}

.title-card .content-container {
  width: 100%;
  height: 380px;
  position: relative;
  padding: 0%;
  display: flex;
  justify-content: center;
  background-color: var(--title-card-bg-color);

}

.title-card .content-container .title-card-background {
  position: absolute;
  width: 100%;
  height: 100%;
  /* background-size: cover;
  background-repeat: no-repeat;
  background-position: center; */
  margin: 0%;
  padding: 0%;
  opacity: 0.6;
  object-fit: cover;
  max-height: 400px;
}

.title-card-box {
  text-align: center;
  z-index: 100;
  padding: 0% 10%;
}

.title-card-box h4 {
  font-size: 58px;
  font-weight: 600;
  letter-spacing: 4px;
  color: var(--title-card-main-font-color);
  text-transform: capitalize;

}

.title-card-box p {
  margin-top: 20px;
  font-size: 18px;
  color: var(--title-card-sub-font-color);
}

@media (max-width: 992px) {

  .title-card .content-container {
    /* padding: 64px 0px; */
    width: 100%;
    height: 260px;
    position: relative;

  }

  .title-card-box {
    text-align: center;
  }

  .title-card-box h4 {
    font-size: 32px;
    font-weight: bold;

  }

  .title-card-box p {
    margin-top: 20px;
    font-size: 16px;
  }

}

/*======================================
	01. Start Team CSS
========================================*/


.team {
  /* margin:40px; */
  padding: 64px 0px 0px 0px;
  min-height: 60vh;
}

.team-heading-container {
  padding: 12px 0px 18px 0px;
  text-align: center;
}

.team-heading {
  font-weight: 500;
  font-family: var(--secondary-font);
  text-transform: uppercase;
  /* text-decoration: underline; */
}

.team-member {
  margin: 15px 0;
  padding: 12px;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  /* width: 80%; */
  /* background-color: #373737; */
  width: 320px;
  border: 1px solid #000000;

  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.2);

}

.team-member .team-image-container {
  overflow: hidden;
  padding: 14px;
  margin: 0;
  border-radius: 12px;

  /* width: 264px; */
  /* height:266px; */



}

.team-member .team-image-container img {
  width: 132px;
  height: 132px;
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  /* border-radius: 12px; */
}



.team-member h4 {
  margin: 0px 0 0;
  padding: 0;
  font-size: 20px;
  font-weight: 600;

}

.team-member p {

  font-size: 14px;
  font-weight: 400;
  color: #555;

}

.team-member .card-content {
  text-align: start;
}

.team-member .card-content h6 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 6px;
}

.team-member .card-content p {
  font-size: 14px;
  font-weight: 400;
}

@media only screen and (max-width: 768px) {
  .team-member .team-image-container {
    width: 256px;
    height: 256px;
    border-radius: 12px;
  }


}

/* .team-member .image-container img {
  -webkit-transform: scale(1) rotate(0) translateY(0);
  -moz-transform: scale(1) rotate(0) translateY(0);
  -o-transform: scale(1) rotate(0) translateY(0);
  -ms-transform: scale(1) rotate(0) translateY(0);
  transform: scale(1) rotate(0) translateY(0);
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.team-member .image-container:hover img {
  -webkit-transform: scale(1.1) rotate(1deg) translateY(12px);
  -moz-transform: scale(1.1) rotate(1deg) translateY(12px);
  -o-transform: scale(1.1) rotate(1deg) translateY(12px);
  -ms-transform: scale(1.1) rotate(1deg) translateY(12px);
  transform: scale(1.1) rotate(1deg) translateY(12px);
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
} */




/*======================================
	01. Start Footer CSS
========================================*/

.footer {
  background-color: var(--footer-bg-color);
}

.media-container {
  padding: 2rem 0rem;
  text-align: center;
}

.media-container p {
  color: var(--footer-font-color);
  font-weight: 500;
}

/*======================================
	01. Start Gallery CSS
========================================*/

/* Responsive image gallery rules begin*/

.image-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  padding: 64px 0px 64px 0px;

}


.image-gallery::after {
  content: "";
  flex-basis: 420px;
}

.image-gallery .image-grid {
  /* flex-basis: 420px; */
  height: 320px;
  position: relative;
  cursor: pointer;
  width: 100%;
  max-width: 424px;
  margin: 4px;
  z-index: 1;
  overflow: hidden;
}

.image-gallery .image-grid img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: .5s ease all;
  /* background-size: cover;
  background-repeat: no-repeat;
  background-position: center; */
  /* max-width: 100%; */
  /* vertical-align: middle; */
  /* border-radius: 5px; */
  /* height: auto; */


}

.image-gallery .image-grid:hover img {
  transform: scale(1.1);
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(57, 57, 57, 0.502);
  top: 0;
  left: 0;
  transform: scale(0);
  transition: all 0.2s 0.1s ease-in-out;
  color: var(--gallery-font-color);
  border-radius: 5px;
  /* center overlay text */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* hover */
.image-gallery .image-grid:hover .overlay {
  transform: scale(1.1);
}



.image-viewer {
  position: fixed;
  background: rgba(0, 0, 0, 0.9);
  top: 5%;
  left: 5%;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  width: 90%;
  height: 90%;
}

.image-viewer img {
  max-width: 90%;
  max-height: 90%;
}

.prev-button,
.next-button {
  position: absolute;
  font-size: 2rem;
  color: #fff;
  background-color: transparent;
  border: none;
  cursor: pointer;
  top: 50%;
}

.close-button {
  position: absolute;
  font-size: 2rem;
  color: #fff;
  cursor: pointer;
  top: 0%;
}

.close-button {
  right: 15px;
}

.prev-button {
  left: 15px;
}

.next-button {
  right: 15px;
}

/*======================================
	01. Start Research CSS
========================================*/

.research {
  background-color: transparent;
  min-height: 60vh;


}

.research .container-fluid {
  width: 80%;
  position: relative;
  word-wrap: break-word;

}

.research .content-container {
  display: flex;
  align-items: flex-start;
  /* background-color: #c9aa70; */
  padding: 0rem 0rem;
  margin: 60px 0px 0 0;
  width: 100%;
}

.research .content-container:last-child {
  margin-bottom: 60px;
}

.research .no-reverse {
  flex-direction: row;

}

.research .reverse {
  flex-direction: row-reverse;

}

.research .card-box {
  padding: 16px;
}

.research .card-box h3 {
  text-align: start;
  font-size: 38px;
  font-weight: 500;
  color: var(--research-main-font-color);
}

.research .card-box p {
  text-align: justify;
  font-size: 18px;
  color: var(--research-sub-font-color);
}

.research .card-box h5 {
  font-size: 20px;
  font-style: italic;
  font-weight: 300;
  color: var(--research-main-font-color);
}

.research .content-container .col-img .image-wrapper {
  /* text-align: center; */
  /* background-color: #61dafb; */
}

.research .content-container .col-img .text-align-end {
  text-align: end;
}

.research .content-container .col-img .text-align-start {
  text-align: start;
  padding: 0 14px;
}

.research .content-container .col-img .image-wrapper img {
  max-width: 540px;
  width: 100%;
  height: auto;
  border-radius: 6px;
}


@media (max-width: 1400px) {
  .research .card-box h3 {
    text-align: start;
    font-size: 26px;
  }

  .research .card-box p {
    text-align: justify;
    font-size: 20px;
  }

  .research .card-box h5 {
    font-size: 20px;
    font-style: italic;
    font-weight: 300;
  }

  .research .content-container .col-img .image-wrapper img {
    width: 460px;
    height: 320px;
    border-radius: 0%;
  }
}

@media (max-width: 1200px) {
  .research .container-fluid {
    width: 96%;

  }

  .research .card-box h3 {
    text-align: start;
    font-size: 22px;
  }

  .research .card-box p {
    text-align: justify;
    font-size: 16px;
  }

  .research .card-box h5 {
    font-size: 16px;
    font-style: italic;
    font-weight: 300;
  }

  .research .content-container .col-img .image-wrapper img {
    width: 380px;
    height: 318px;
    border-radius: 0%;
  }
}

@media (max-width: 970px) {
  .research .container-fluid {
    width: 96%;

  }

  .research .card-box h3 {
    text-align: start;
    font-size: 20px;
  }

  .research .card-box p {
    text-align: justify;
    font-size: 14px;
  }

  .research .card-box h5 {
    font-size: 14px;
    font-style: italic;
    font-weight: 300;
  }

  .research .content-container .col-img .image-wrapper img {
    width: 340px;
    height: 246px;
    border-radius: 0%;
  }
}

@media (max-width: 876px) {
  .research .container-fluid {
    width: 80%;

  }

  .research .no-reverse {
    flex-direction: column-reverse;
    margin: 30px 0;
  }

  .research .reverse {
    flex-direction: column-reverse;

  }


  .research .content-container .col-img .image-wrapper img {
    width: 100%;
    height: auto;
    border-radius: 0%;
  }

  .research .card-box {
    padding: 16px 0px;
  }
}


/*======================================
	01. Start publication CSS
========================================*/
.publication {
  min-height: 60vh;

}

.publication .publication-container {
  margin: 12px 12px;
  /* Adjust the margin as needed */
  padding: 20px;
  border-radius: 8px;
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
}

.publication .publication-container .index-wrapper {
  /* background-color:blue; */
  padding-left: 18px ;
  padding-right: 18px;
}

.publication .publication-container .index-wrapper h5 {

  font-weight: 800;
}

.publication .publication-container .content-wrapper {
  /* background-color:rgb(224, 132, 27); */
  display: flex;
  word-wrap: break-word;

}

.publication .publication-container .content-wrapper .calender-wrapper {
  margin: 0px 0px 8px 0px;
  display: flex;
  align-items: center;
  color: var(--publication-cal-color);
}

.publication .publication-container .content-wrapper .calender-wrapper p {
  margin: 0%;
}

.publication .publication-container .content-wrapper h5 {
  font-weight: 600;
  letter-spacing: 1px;
  color: var(--publication-main-font-color);
}

.publication .publication-container .content-wrapper p {
  margin: 6px 0px;
  font-size: 15px;
  color: var(--publication-sub-font-color);
  text-align: justify;
}

.publication .publication-container .content-wrapper .download-wrapper {
  display: flex;
}

.publication .publication-container .content-wrapper .download-wrapper .download-box {
  /* background-color:black; */
  padding: 8px 14px;
  margin-right: 12px;
  display: flex;
  align-items: center;
}

.publication .publication-container .content-wrapper .download-wrapper .pdf {
  color: var(--publication-pdf-color);
}

.publication .publication-container .content-wrapper .download-wrapper .site {
  color: var(--publication-site-color);
}

.publication .publication-container .content-wrapper .download-wrapper .pdf a {
  color: var(--publication-pdf-color);
}

.publication .publication-container .content-wrapper .download-wrapper .site a {
  color: var(--publication-site-color);
}
.publication .publication-container .download-box{
  padding: 10px 20px;
    cursor: pointer;
}
.publication .publication-container .db-button {
 
  background-color: #fff;
  color: rgb(61, 77, 218);
  font-weight: 600;
  border: none;
  border-radius: 5px;
  margin: 5px;
}

@media (max-width: 876px) {
  .publication .publication-container .content-wrapper .download-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

  }
}

/* AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA */
.home-gallery {
  display: flex;
  justify-content: center;
  padding: 64px 64px 0px 64px;
}


.home-gallery h3 {
  font-size: 48px;
  text-align: center;
  font-weight: 800;
  letter-spacing: 2px;
}

@media (max-width: 972px) {
  .home-gallery h3 {
    font-size: 38px;
  }

  .research .one h1 {
    font-size: 32px;
  }
}

.home-gallery-button {
  display: flex;
  justify-content: center;
  padding-bottom: 64px;
}

.home-gallery-button .btn {
  margin-top: 18px;
  display: inline-block;
  padding: 13px 20px;
  font-weight: 600;
  color: var(--nav-bg-color);
  text-decoration: none;
  position: relative;
  background: transparent;
  border: 1px solid var(--nav-bg-color);
  font-size: 12px;
  letter-spacing: 2px;
  text-align: center;
  text-indent: 2px;
  text-transform: uppercase;
  transition: color 0.1s linear 0.05s;
}

.home-gallery-button .btn::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px;
  background: #cccccc;
  z-index: 1;
  opacity: 0;
  transition: height 0.2s ease, top 0.2s ease, opacity 0s linear 0.2s;
}

.home-gallery-button .btn::after {
  transition: border 0.1s linear 0.05s;
}

.home-gallery-button .btn .btn-inner {
  position: relative;
  z-index: 2;
}

.home-gallery-button .btn:hover {
  color: var(--nav-font-color);
  transition: color 0.1s linear 0s;
}

.home-gallery-button .btn:hover::before {
  top: 0;
  height: 100%;
  opacity: 1;
  transition: height 0.2s ease, top 0.2s ease, opacity 0s linear 0s;
}

.home-gallery-button .btn:hover::after {
  border-color: var(--slider-btn-hover-color);
  transition: border 0.1s linear 0s;
}


/* heading style */

.research .one {
  padding: 32px 0px 24px 0px;
}

.research .one h1 {

  position: relative;
  padding: 0;
  margin: 0;
  /* font-family: "Raleway", sans-serif; */
  font-weight: 600;
  font-size: 36px;
  color: #080808;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;

  text-align: center;
  /* text-transform: uppercase; */
  padding-bottom: 5px;
}

.research .one h1:before {
  width: 30px;
  height: 6px;
  display: block;
  content: "";
  position: absolute;
  bottom: 3px;
  left: 50%;
  margin-left: -14px;
  background-color: var(--nav-bg-color);
}

.research .one h1:after {
  width: 320px;
  height: 1px;
  display: block;
  content: "";
  position: relative;
  margin-top: 18px;
  left: 50%;
  margin-left: -160px;
  background-color: var(--nav-bg-color);
}


section.footer .logo-block {
  padding: 20px 0;
}

section.footer .logo-block img {
  max-height: 100%;
  max-width: 250px;
  width: 110px;
}

section.footer .address-area ul {
  list-style: none;
  display: inline-block;
  text-align: left;
  color: var(--footer-font-color);
  font-size: 14px;
}

section.footer .logo-block,
section.footer .address-area {
  display: flex;
  align-items: center;
  justify-content: center;
}

section.footer .copy-right {
  padding-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}

.carousel .arrow {
  display: none;
}

.research-button {
  display: flex;
}

/* .research-button .active{background: #fff; color: #ad8e44; transition: .4s cubic-bezier(.88,-.35,.565,1.35);} */
.research .research-button .research-link-style {
  display: block;
  width: 50%;
  text-align: center;
  text-decoration: none;
  background-color: #AD8E44;
  padding: 12px;
  border: none;
  transition: 1s;
  cursor: pointer;
}

.research .research-button .research-link-style a{

  text-align: center;
  text-decoration: none;
  color: #FFFFFF;
  font-size: 24px;

}

.research .research-button .research-link-style-active {
  display: block;
  width: 50%;
  text-align: center;
  text-decoration: none;
  background-color: #FFFFFF;
  padding: 12px;
  border: none;
  transition: 1s;
  cursor: pointer;
}

.research .research-button .research-link-style-active a{
  text-align: center;
  text-decoration: none;
  color: var(--nav-bg-color);
  font-size: 24px;

}
@media (max-width: 760px) {
  .research .research-button .research-link-style a{
    font-size: 18px;
  }
  .research .research-button .research-link-style-active a{

    font-size: 18px;
  
  }
}

@media (max-width: 540px) {
  .research .research-button .research-link-style a{
    font-size: 14px;
  }
  .research .research-button .research-link-style-active a{

    font-size: 14px;
  
  }
  
}

.research .one hr {
  height: 2px;
  width: 25%;
  position: relative;
  margin: 5px auto;
}


section.research-section {
  width: 100%;
  z-index: 99;
}

.research-button ul li a {
  position: relative;
  color: var(--nav-font-color);
  font-size: 1.2rem;
  font-weight: 500;
  margin: 0 4rem;
  height: 60px;
  line-height: 56px;
  display: inline-block;
  transition: background .2s;
  text-decoration: none;
  text-transform: uppercase;
}

.research-button ul li a:hover {
  color: rgba(0, 0, 0, 0.8);
}

.research-button ul li a:hover::after {
  content: "";
  height: 2px;
  position: absolute;
  left: 0;
  bottom: 10px;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  transition: all .3s cubic-bezier(0.4, 0, 0.2, 1);
  display: inline-block;
  visibility: visible;
}

 .card-detail .btn {
  margin-top: 0;
}

.card-detail {
  background: #ffffff;
  border-radius: 2px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
  margin-top: 60px;
  margin-bottom: 30px;
}



.card-detail .card-details {  
  display: flex;
  justify-content: flex-end;
  margin: 40px 0;
}

.card-detail .card-details .col-lg-3 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.card-detail .card-details img {
  height: 300px;
  width: 300px;
  /* z-index: 999; */
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  object-position: center;
  border-radius: 50%;
  transition: .2s all;
  -webkit-transition: .2s all;
  /* filter: drop-shadow(2px 2px) */
}

.card-detail .card-details img:hover {
  -webkit-filter: brightness(50%);
  filter: brightness(85%);
  /* filter: drop-shadow(2px 2px); */
}

.card-detail .agent-details {
  padding-left: 60px;
}
.card-detail .agent-details .line{
  height: 1px;
  width: 100%;
  background-color:#CCB98B;
  margin: 20px 0px;
}
.card-detail .card-details .w-100.detail-para {
  padding-top: 0px;
}

.card-detail .card-details h2 {
  font-size: 28px;
  text-transform: capitalize;
  font-weight: 600;
}

.card-detail .card-details span {
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 400;
}

.card-detail .card-details p {
  font-size: 18px;
  margin-bottom: 34px;
  text-align: justify;
}

.card-detail .card-details .btn {
  color: var(--nav-font-color);
  margin-bottom: 20px;
  color: var(--nav-bg-color);
  border: 1px solid var(--nav-bg-color);
}

.card-detail .card-details .btn span {
  font-size: 12px;
  text-transform: uppercase;
}


.card-detail .card-details .detail-head {
  padding-top: 20px;
}

section.team .card {
  border: 0;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
  border-radius: 6px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 60px;
}


section.team .card .card-header {
  height: 260px;
  background-color: var(--team-top-cont-bg-color);
}

section.team .card .card-header .card-avatar {
  width: 130px;
  height: 130px;
  box-shadow: none;
}

section.team .card .card-header .card-fullname {
  font-size: 20px;
}




.publication-container input:checked~label {
  font-size: 0;
}

.publication-container input:checked~label.read-more:before {
  content: "Read Less";
  font-size: 15px;
}

.publication-container input:checked~div.accordion-height {
  max-height: 10000px;
}

.contact .contact-area {
  margin-bottom: 20px;
}

@media (max-width:1400px) and (min-width:992px) {
  .team .members-card {
    width: 33.3%;
  }
}

@media (max-width: 710px) {
  .research-button ul li a {
    font-size: 16px;
    line-height: normal;
    height: auto;
    margin: 0 8px;
  }

  .research-button ul {
    text-align: center;
  }
}



/* .collaborators{justify-content: center; margin-top: 28px;} */
/* .collaborators .col-detail{border-radius: 8px; height: 250px; margin: 0 0 28px ;
  -webkit-box-shadow: inset -4px -4px 4px 0 rgba(255, 255, 255, 0.7), inset 4px 4px 4px 0 rgba(174, 174, 192, 0.2);
  box-shadow: inset -4px -4px 4px 0 rgba(255, 255, 255, 0.7), inset 4px 4px 4px 0 rgba(174, 174, 192, 0.2); 
  display: flex; align-items: center; flex-direction: column;}
.collaborators .col-detail .col-head h2{font-size: 24px; text-transform: capitalize; color: var(--nav-bg-color);}
/* .collaborators .col-detail .col-head::after{border: 1px solid rgb(0, 0, 0); content: "";} */
/* .collaborators .col-detail .col-header li{font-size: 20px; list-style: none;}
.collaborators .col-detail .col-head{margin: 18px 0;} */


.opportunities {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.opportunities .header-section {
  text-align: center;
  margin: 40px;
}

.opportunities .header-section h1 {
  font-size: 48px;
  font-weight: 600;
}

.opportunities .container {
  display: flex;
  /* max-width: 800px; */
  overflow: hidden;
  padding: 3% 0%;


}

.opportunities .photo-section,
.description {
  flex: 1;
}

.opportunities .photo-section {
  /* overflow: hidden; */
  /* position: relative; */
  /* background-color: #636B6F; */
}

.opportunities .photo-section img {

  width: 95%;
  height: auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.opportunities .description {
  padding: 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: justify;
}

.opportunities .description h5 {
  color: #000;
  font-size: 16px;
  font-weight: 600;
}

.opportunities .description .email {
  color: var(--nav-bg-color);
}

.opportunities .description p {
  color: #666;
  line-height: 1.6;
}

.opportunities .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 100%);
}

@media (max-width: 670px) {

  /* Adjust the breakpoint to your preference */
  .opportunities .photo-with-description {
    flex-direction: column;
    align-items: center;

  }

  .opportunities .photo-section,
  .opportunities .description {
    width: 100%;
  }

  .opportunities .header-section h1 {
    font-size: 36px;
    font-weight: 600;
  }
}


.alumini .card-main {
  position: unset;
}

.alumini .card-social {
  position: unset;
}

.alumini .card {
  justify-content: center;
  align-items: center;
}

section.alumini .card .card-header {
  height: 120px;
  position: unset;
  justify-content: center;
  flex-direction: column;
}

section.alumini .card .card-header .card-fullname {
  position: unset;
  width: 100%;
  margin-bottom: 14px;
}

section.alumini .card .card-header .card-jobtitle,
section.alumini .card .card-header .card-job-year {
  position: unset;
  transform: none;
  text-align: center;
}
section.alumini .card .card-main .card-social{
  position: unset;
  width: 100%;
  font-size: 14px;
}
.collaborators .col-detail .col-header ul {
  padding: 0;
}

.publication-switch-container {
  display: flex;
}

.publication-switch-container .switch-box {
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
  padding: 12px 0px;
}


.publication-switch-container .non-active {
  display: block;
  width: 50%;
  text-align: center;
  text-decoration: none;
  color: #ffffff;
  background-color: var(--nav-bg-color);
  padding: 12px;
  border: none;
  transition: 1s;
  cursor: pointer;
  font-size: 18px;
}


.publication-switch-container .active  {
  display: block;
  width: 50%;
  text-align: center;
  text-decoration: none;
  color: var(--nav-bg-color);
  background-color: #FFFFFF;
  padding: 12px;
  border: none;
  transition: 1s;
  cursor: pointer;
  font-size: 18px;

}
.publication-switch-container .switch-box a {
  font-size: 24px;
  margin: 0%;
}
@media (max-width: 760px) {
  .publication-switch-container .switch-box a {
    font-size: 18px;
    margin: 0%;
  }
}






.publication-container input {
  display: none
}

.publication .one {
  margin-left: 1%;
  padding: 32px 0px 24px 0px;
}

.publication .one h1 {
  position: relative;
  padding: 0;
  margin: 0;
  font-weight: 600;
  font-size: 32px;
  color: #080808;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  padding-bottom: 5px;
}

.publication .one h1:before {
  width: 30px;
  height: 5px;
  display: block;
  content: "";
  position: absolute;
  bottom: 3px;
  background-color: var(--nav-bg-color);
}

.publication .one h1:after {
  width: 240px;
  height: 1px;
  display: block;
  content: "";
  margin-top: 18px;
  background-color: var(--nav-bg-color);
}

.publication .publication-container .site a {
  overflow: auto;
  text-overflow: ellipsis;
}















































































/*======================================
	01. Start card CSS
========================================*/

.card {
  max-width: 420px;
  margin: 42px auto;
  /* overflow-y: auto; */
  position: relative;
  z-index: 1;
  /* overflow-x: hidden; */
  background-color: var(--team-below-cont-bg-color);
  display: flex;
  /* transition: 0.3s; */
  flex-direction: column;
  border-radius: 14px;
  /* box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.2); */
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  padding: 0px;
}

/* .card.is-active .card-header {
  height: 80px;
} */


.card-header {
  border-radius: 10px;
  position: relative;
  display: flex;
  /* height: 220px; */
  flex-shrink: 0;
  width: 100%;
  transition: 0.3s;
  /* background-color: var(--team-top-cont-bg-color); */
}



.card-avatar {
  width: 100px;
  height: 100px;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  object-position: center;
  object-fit: cover;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) translateY(-82px);
  top: 40%;
}

.card-fullname {
  position: absolute;
  bottom: 20%;
  font-size: 22px;
  line-height: 22px;
  font-weight: 700;
  text-align: center;
  width: calc(100% - 36px);
}

.card-jobtitle {
  position: absolute;
  bottom: 0;
  font-size: 12px;
  white-space: nowrap;
  font-weight: 500;
  opacity: 0.7;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  margin: 0;
  left: 50%;
  transform: translateX(-50%) translateY(-24px);
}

.card-job-year {
  position: absolute;
  bottom: 0;
  font-size: 11px;
  white-space: nowrap;
  font-weight: 500;
  opacity: 0.7;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  margin: 0;
  left: 50%;
  transform: translateX(-50%) translateY(-7px);
}

.card-main {
  position: relative;
  flex: 1;
  display: flex;
  padding-top: 10px;
  flex-direction: column;
}

.card-subtitle {
  font-weight: 700;
  font-size: 13px;
  margin-bottom: 8px;
}

.card-content {
  padding: 20px;
}

.card-desc {
  line-height: 1.6;
  color: var(--team-sub-font-color);
  font-size: 14px;
  margin: 0;
  font-weight: 400;
  cursor: pointer;

}
.card-desc span{
  line-height: 1.6;
  color: #AD8E44;
  font-size: 14px;
  margin: 0;
  font-weight: 400;
}
.card-social {
  display: flex;
  align-items: center;
  padding: 0 20px 12px 20px;
  bottom: 0;
  /* position: absolute; */
}

.card-social a {
  color: var(--team-main-font-color);
  height: 32px;
  width: 32px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  background-color: var(--team-top-cont-bg-color);
  border-radius: 50%;
  margin-right: 6px;
}

.card-social a:hover {
  background-color: var(--team-main-font-color);
  color: rgb(255, 255, 255);

}

.card-section {
  display: none;
}

.card-section.is-active {
  display: block;
  animation: fadeIn 0.6s both;
  height: 100%;
  position: relative;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translatey(40px);
  }

  100% {
    opacity: 1;
  }
}

.card-content input {
  display: none
}

.card-content input:checked~label {
  font-size: 0;
}

.card-content input:checked~label.read-more:before {
  content: "Read Less";
  font-size: 15px;
}


.card-content .accordion-height {
  position: relative;
  /* max-height: 70px; */
  overflow: hidden;
  -webkit-transition: max-height .25s ease-in-out;
  -moz-transition: max-height .25s ease-in-out;
  -ms-transition: max-height .25s ease-in-out;
  -o-transition: max-height .25s ease-in-out;
  transition: max-height .25s ease-in-out;
}

.accordion-height {
  /* position: relative; */
  /* height: 176px; */
  /* overflow: hidden; */
  margin: 6px 0px;
  /* background-color: #AD8E44; */
  -webkit-transition: max-height .25s ease-in-out;
  -moz-transition: max-height .25s ease-in-out;
  -ms-transition: max-height .25s ease-in-out;
  -o-transition: max-height .25s ease-in-out;
  transition: max-height .25s ease-in-out;
}

label.read-more {
  display: block;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;
  color: var(--publication-site-color);
  text-align: center;
  padding: 10px 8px;
  margin: auto;
  margin-bottom: 20px;
  text-decoration: underline;
}